<template>
    <footer class="footer">
        <router-link to="/" class="logo logo--footer"><SVGlogo /></router-link>

        <article class="footer-col">
            <h3>Get In Touch</h3>
            <p>208-210 McIntyre Rd, <br>
            Sunshine, 3020</p>

            <!-- TODO: Make clickable number -->
            <p>(03) 9356 4202</p>
            <a style="margin-bottom: 0.5em; display: block;" href="mailto:sales@melbournemalibu.com.au">Email Sales</a>
            <a style="margin-bottom: 0.5em; display: block;" href="mailto:service@melbournemalibu.com.au">Email Servicing</a>
            <a href="mailto:parts@melbournemalibu.com.au">Email Parts</a>
        </article>

        <article class="footer-col">
            <h3>Showroom Hours</h3>
            <ul class="hours">
                <li>Monday - Friday: 9am - 5pm</li>
                <li>Saturday: 9am - 3pm</li>
            </ul>

            <h3>Service Hours</h3>
            <ul class="hours">
                <li>Monday - Friday: 8am - 5pm</li>
            </ul>
        </article>

        <article class="footer-col">
            <h3>Navigate</h3>
            <AppNav>
                <li><router-link to="/insurance">Insurance</router-link></li>
            </AppNav>
        </article>


        <section class="footer__bottom">
            <p>Copyright &copy; Melbourne Malibu</p>
            <p>ACN: 630007153</p>
        </section>
    </footer>
</template>
