<template>
    <div class="spinner__wrapper">
        <div class="spinner">
            <div class="spinner__dot"></div>
            <div class="spinner__dot"></div>
            <div class="spinner__dot"></div>
            <div class="spinner__dot"></div>
            <div class="spinner__dot"></div>
            <div class="spinner__dot"></div>
        </div>
    </div>
</template>
