export const toTitleCase = val => {
    const splitWords = val.split(' ').map(c => c.charAt(0).toUpperCase() + c.slice(1)).join(' ')

    return splitWords
}

export const kebabToTitle = val => {
    return val.split('-').map(c => c.charAt(0).toUpperCase() + c.slice(1)).join(' ')
}

export const capitalise = str => str.charAt(0).toUpperCase() + str.slice(1)

export const chunkArray = (array, size) => {
    if (array.length <= size) return [array]

    return [array.slice(0, size), ...chunkArray(array.slice(size), size)]
}


export const fraction = val => new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
}).format(val)

export const format = val => new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
}).format(val)

export const formatMoney = val => val % 1 === 0 ? fraction(val) : format(val)

export const getImageUrl = imgData => {
    if (!imgData) return

    const { size, url, formats } = imgData

    const maxFileSize = 450 // even 450 is big
    if (size > maxFileSize) return formats.large.url

    return url
}
