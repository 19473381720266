
<template>
    <nav :class="[{ 'nav' : isPrimaryNav }, { 'nav--open' : navIsOpen && isPrimaryNav }]" v-if="navItems">
        <ul :class="{ 'nav__list' : isPrimaryNav }">
            <li :class="{ 'nav__item' : isPrimaryNav }" v-for="{ id, title, url } in navItems" :key="id">
                <router-link @click="$emit('closeNav')" :to="{ path: url }">{{ title }}</router-link>
            </li>
            <slot></slot>
        </ul>
    </nav>
</template>

<script setup>
import { computed } from 'vue'
import { useQuery } from '@/composables/useQuery'

defineProps({
    isPrimaryNav: {
        type: Boolean,
        default: false
    },

    navIsOpen: {
        type: Boolean,
        default: false
    }
})

const { data } = useQuery('nav', '/menus/1?populate=*')

const navItems = computed(() => {
    if (!data?.value) return null

    const returnedNavStructure = data.value?.data?.attributes?.items?.data
    const newNavItems = returnedNavStructure.reduce((acc, item) => {
        acc.push({
            id: crypto.randomUUID(),
            title: item.attributes.title,
            target: item.attributes.target,
            order: item.attributes.order,
            url: item.attributes.url
        })
        return acc
    }, [])
    .sort((a, b) => a.order - b.order)

    return newNavItems
})
</script>

