<template>
  <article class="p-img__images" v-if="images">
    <figure class="p-img__img">
      <img :src="images.thumbs[currentImage].src" :alt="images.thumbs[currentImage].alt" />

      <button class="p-img__arr p-img__arr--left" @click="handlePrevSlide">&#10094;</button>
      <button class="p-img__arr p-img__arr--right" @click="handleNextSlide">&#10095;</button>
    </figure>

    <div class="p-img__thumbs">
      <button v-for="({ src, alt }, index) in thumbnails" :key="index">
        <img :src="src" :alt="alt" @click="currentImage = index" />
      </button>
    </div>
  </article>

  <article class="p-img__images" v-else>
    Placeholder
  </article>
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  images: {
    type: Object,
    default: null
  }
})

const currentImage = ref(0)
const currentSlideOffset = ref(0)
const chunkAmount = ref(4)
const thumbnails = computed(() => {
  if (!props.images?.thumbs) return null
  const { thumbs } = props.images
  return thumbs.slice(currentSlideOffset.value, currentSlideOffset.value + chunkAmount.value)
  // return chunkArray(thumbs, chunkAmount.value)
})

const handleNextSlide = async () => {
  console.log(currentSlideOffset.value, props.images.thumbs.length - 1)
  if (currentSlideOffset.value >= props.images.thumbs.length - 1) return
  currentSlideOffset.value ++
  currentImage.value ++
}

const handlePrevSlide = async () => {
  if (currentSlideOffset.value <= 0) {
    currentImage.value = 0
    return
  }
  currentSlideOffset.value--
  currentImage.value--
}
</script>

