<template>
    <div class="grid-line__wrapper">
        <div class="grid-line grid-line-1" />
        <div class="grid-line grid-line-2" />
        <div class="grid-line grid-line-3" />
        <div class="grid-line grid-line-4" />
        <div class="grid-line grid-line-5" />
    </div>
</template>


