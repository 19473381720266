<template>
    <AppHeader />
    <transition name="change-page" mode="out-in">
        <GridOverlay :key="$route.path" />
    </transition>
    <router-view v-slot="{ Component }">
        <transition name="change-page-bg" mode="out-in">
            <div :key="$route.path">
                <component :is="Component" />
            </div>
        </transition>
    </router-view>
    <AppFooter />
</template>


