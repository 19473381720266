<template>
    <section :class="sectionClass">
        <slot></slot>
    </section>
</template>

<script>
export default {
    props: {
        name: String
    },

    computed: {
        sectionClass () {
            return this.name ? `section section-${name}` : 'section'
        }
    }
}
</script>

