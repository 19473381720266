<template>
<svg
    class="mouse"
    id="mouseicon"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 43"
    aria-labelledby="mousetitle"
    role="presentation"
>
    <title id="mousetitle">Scroll Down Icon</title>
  <defs>
    <clipPath id="clip-path" transform="translate(-0.5 -1)">
      <rect id="mask" x="-0.66" y="-1.57" width="27.28" height="46.97" fill="none"/>
    </clipPath>
  </defs>
  <rect id="base" x="1.5" y="2" width="23" height="41" rx="10" transform="translate(25.5 44) rotate(180)" fill="none" stroke="#b3b3b3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <g id="wheel">
    <line x1="12.5" y1="15.41" x2="12.5" y2="7.88" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
  <circle id="wheel-circle" cx="12.45" cy="7.51" r="2" fill="#fff"/>
  <g id="highlight">
    <g clip-path="url(#clip-path)">
      <rect id="highlight-2" data-name="highlight" x="1.5" y="2" width="23" height="41" rx="10" transform="translate(25.5 44) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </g>
</svg>
</template>
