<template>
    <section>
        <h2>News</h2>
        <article v-for="{ id, featured_image, Title, Excerpt, slug, publishedAt } in normalisedPosts" :key="id">
            <figure v-if="!!featured_image?.data">
                <router-link :to="{ path: slug }">
                    <img
                        :src="featured_image.data.attributes.url"
                        :alt="featured_image.data.attributes.alternativeText ||
                            featured_image.data.attributes.name ||
                            Title"
                    />
                </router-link>
            </figure>

            <figure class="thumb-fallback" v-else>
                <SVGlogo />
            </figure>

            <header>
                <h3><router-link :to="{ path: slug }">{{ Title }}</router-link></h3>
                <div class="meta">{{ format(new Date(publishedAt), 'dd MMM yyyy') }}</div>
            </header>

            <p>{{ Excerpt }}</p>

            <router-link :to="{ path: slug }" class="btn btn--secondary">Read more</router-link>
        </article>
    </section>
</template>

<script setup>
import { computed } from 'vue'
import { format } from 'date-fns'

const props = defineProps({
    posts: {
        type: Array,
        default: () => []
    }
})

const normalisedPosts = computed(() => {
    if (!props.posts.length) return []

    return props.posts.map(({ id, attributes }) => ({ id, ...attributes, slug: `/posts/${attributes.slug}` })).slice(0, 3)
})
</script>

<style lang="scss" scoped>
    section {
        display: grid;
        grid-template-columns: 0.25fr 1fr 0.25fr 1fr 0.25fr 1fr 0.25fr;

        @media only screen and (max-width: 768px) {
            grid-template-columns: 0.5fr 0.5fr repeat(4, 1fr) 0.5fr 0.5fr;
        }
    }

    h2 { grid-column: 2 / -1; }
    h3 {
        margin-top: var(--space-md);
        a {
            color: currentColor;
            &:hover { color: var(--color-secondary); }
        }
    }

    .meta {
        font-size: 0.9rem;
        font-weight: var(--font-medium);
        color: var(--color-secondary-lighter);
        margin: var(--space-sm) 0;
        font-style: italic;
    }

    article {
        @media only screen and (max-width: 767px) {
            grid-column: 2 / -2;
            &:not(:last-of-type) { margin-bottom: var(--space-xl); }
        }

        @media only screen and (min-width: 768px) {
            &:first-of-type { grid-column: 2; }
            &:nth-of-type(2) { grid-column: 4; }
            &:nth-of-type(3) { grid-column: 6; }
        }
    }

    figure, img {
        max-width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
    }

    .btn {
        margin-top: var(--space-xs);
    }
</style>

<style lang="scss">
.thumb-fallback {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-border);
    border-radius: 4px;

    svg {
        opacity: 0.15;
        max-width: 150px;
        margin: auto;
        * { fill: black; }
    }
}
</style>
