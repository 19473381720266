<template>
    <label :for="id" :class="{ 'sr-only': srOnly }"
        >{{ label }}<span v-if="isRequired" class="required">*</span></label
    >
    <input
        type="text"
        :value="modelValue"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
    />
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [String, Number],
            default: ''
        },

        id: {
            type: [String, Number],
            required: true
        },

        label: {
            type: String,
            required: true
        },

        srOnly: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isRequired() {
            return Object.keys(this.$attrs).includes('required')
        }
    }
}
</script>
