<template>
    <header class="header">
        <router-link class="header__logo" exact to="/"><SVGlogo /></router-link>

        <button :class="['nav__toggle', { 'nav__toggle--open' : navIsOpen }]" @click="toggleNav">
            <span class="nav__toggle-line"></span>
            <span class="nav__toggle-line"></span>
            <span class="nav__toggle-line"></span>
        </button>

        <AppNav
            class="header-nav"
            @closeNav="navIsOpen = false"
            :navIsOpen="navIsOpen"
            isPrimaryNav
        />
    </header>
</template>

<script setup>
import { ref } from 'vue'

const navIsOpen = ref(false)
const toggleNav = () => {
    navIsOpen.value = !navIsOpen.value
}

</script>

