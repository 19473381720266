import { computed } from 'vue'
import { useQuery as vueQuery } from '@tanstack/vue-query'
import { Axios } from '@/helpers/axios'
import { formatMoney } from '@/helpers'
import router from '@/router'

const stripUrlQuery = (url) => {
  const urlObj = new URL(url)
  urlObj.search = ''
  return urlObj.href
}

const normaliseStockData = (data) => data.map(({
  BuildDate,
  Colours,
  CreatedUtc,
  Description,
  Identifier,
  LastModifiedUtc,
  ListingType,
  Media,
  OdometerReadings,
  PriceList,
  Registration,
  SaleStatus,
  SaleType,
  Specification
}) => ({
    id: Identifier,
    year: BuildDate?.Year,
    colours: Colours,
    description: Description,
    creadedAt: CreatedUtc,
    modifiedAt: LastModifiedUtc,
    condition: ListingType,
    thumbnail: Media?.Photos[0]?.Url && stripUrlQuery(Media.Photos[0].Url),
    photos: Media?.Photos?.map(({ Url }) => stripUrlQuery(Url)) || [],
    odometer: OdometerReadings,
    price: formatMoney(PriceList[0].Amount),
    registration: Registration?.Number,
    saleStatus: SaleStatus,
    saleType: SaleType,
    attributes: Specification?.Attributes?.map(({ Name, Value }) => ({ name: Name, value: Value })) || [],
    make: Specification?.Make?.includes('Axis') ? 'Axis' : Specification?.Make,
    model: Specification?.Model,
    title: Specification?.Title
  })
)

const fetcher = async (url) => {
  const { data } = await Axios.get(url)

  if (url.includes('/page') || url.includes('/posts')) {
    if (data?.data.length === 0) return router.replace('/404')
  }

  return data
}

export const useQuery = (key, url) => {
  const { isLoading, isError, data: nestedData, error } = vueQuery({
    queryKey: [key],
    queryFn: async () => {
      const res = await fetcher(url)
      return res
    }
  })

  const data = computed(() => {
    if (nestedData.value) {
      if (key === 'posts') console.log(nestedData.value)
      let resData = key === 'home' || key === 'boatSlider'
        ? nestedData?.value?.data?.attributes || nestedData?.value?.data
        : nestedData?.value

      if (key === 'stock') resData = normaliseStockData(resData
          .map(({ payload }) => payload)
          .filter(({ Identifier }) => Identifier)
          .filter(({ SaleStatus }) => SaleStatus?.toLowerCase() !== 'withdrawn')
        )

      return resData
    }
    return null
  })

  return { isLoading, isError, data, error }
}

// const res = await Axios.get('https://api.melbournemalibu.com.au/get-boats')
