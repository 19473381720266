<template>
    <form v-if="!success" @submit="handleSubmit" ref="signupForm" action="https://melbournemalibu.us8.list-manage.com/subscribe/post?u=363fb8b1426eb780e422e2fcf&amp;id=e04582a028" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
            <div class="mc-field-group form__group">
                <label for="mce-EMAIL">Email Address<span class="asterisk">*</span></label>
                <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
            </div>
            <div class="mc-field-group form__group">
                <label for="mce-FNAME">First Name </label>
                <input type="text" value="" name="FNAME" class="" id="mce-FNAME">
            </div>
            <div class="mc-field-group form__group">
                <label for="mce-LNAME">Last Name </label>
                <input type="text" value="" name="LNAME" class="" id="mce-LNAME">
            </div>
            <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style="display:none"></div>
                <div class="response" id="mce-success-response" style="display:none"></div>
            </div>

            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_363fb8b1426eb780e422e2fcf_e04582a028" tabindex="-1" value=""></div>
            <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="btn btn--primary btn--large"></div>
        </div>
    </form>

    <AppAlert type="success" v-else><strong>Thanks! You're all signed up. Keep an eye out on your emails for great offers.</strong></AppAlert>
</template>

<script setup>
import { ref } from 'vue'
// Mailchimp code - provided by MC
// eslint-disable-next-line
(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';}(jQuery));var $mcj = jQuery;

const success = ref(false)
const handleSubmit = async () => {
    setTimeout(() => {
        success.value = true
    }, 250);
}
</script>
