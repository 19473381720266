<template>
    <transition name="b-slide-left" mode="out-in">
        <AppSection name="boat_slide" class="slider slider--boat" :key="currBoat.id">
            <h2 class="slider__title">
                {{ toTitleCase(currBoat.brand) }}
                <span class="title__alt">{{ currBoat?.brand_model }} {{ currBoat?.brand_model2 }}</span>
            </h2>

            <SliderArrows
                :slidesLength="3"
                :slideInd="boatIndex"
                :loop="true"
                @prev-slides="prevBoat"
                @next-slides="nextBoat"
            >
                <template #prevLabel>
                    {{
                        boatIndex > 0
                            ? boat_slide[boatIndex - 1].brand_model2 || boat_slide[boatIndex - 1].brand_model
                            : boat_slide[boat_slide.length - 1].brand_model2 || boat_slide[boat_slide.length - 1].brand_model
                    }}
                </template>
                <template #nextLabel>
                    {{
                        boat_slide.length - 1 > boatIndex
                            ? boat_slide[boatIndex + 1]?.brand_model2 || boat_slide[boatIndex + 1].brand_model
                            : boat_slide[0]?.brand_model2 || boat_slide[0].brand_model
                    }}
                </template>
            </SliderArrows>

            <div class="slider__grid-block grid-bg-block grid-bg-block--grey" />

            <AppContent class="slider__content" v-html="currBoat.description" />

            <footer class="slider__footer slider__actions">
                <router-link
                    v-for="{ id, btn_label, btn_url, btn_type } in cta"
                    :key="id"
                    :to="btn_url"
                    :class="`btn btn--${btn_type}`"
                >
                  {{ btn_label }} <i v-if="btn_type === 'text'">&#10095;</i>
                </router-link>
            </footer>

            <figure class="slider__image">
                <img
                    :src="getImageUrl(currBoat.image?.data?.attributes)"
                    :alt="`${currBoat.brand} ${currBoat.brand_model} ${currBoat.brand_model2}`"
                />
            </figure>
            <figure class="slider__logo">
                <component :is="`SVGlogo-${currBoat.brand.toLowerCase()}`" />
            </figure>
        </AppSection>
    </transition>
</template>

<script setup>
import { ref, computed } from 'vue'
import { toTitleCase, getImageUrl } from '@/helpers'


const props = defineProps({
    boat_slide: {
        type: Object,
        default: () => {}
    },
    cta: {
        type: [Array, Object],
        default: () => []
    }
})

const boatIndex = ref(0)
const currBoat = computed(() => props.boat_slide[boatIndex.value])

const nextBoat = () => {
    boatIndex.value + 1 < props.boat_slide.length
        ? boatIndex.value++
        : (boatIndex.value = 0)
}
const prevBoat = () => {
    boatIndex.value > 0
        ? boatIndex.value--
        : (boatIndex.value = props.boat_slide.length - 1)
}
</script>
