<template>
    <header :class="`p-header ${postTitle && 'p-header--margined'}`">
      <div class="p-header__inner">
        <figure class="p-header__bg">
          <img :src="headerImg" :alt="headerAlt" />
        </figure>

        <button v-if="showBackBtn">Back</button>
        <span ref="fitText" class="p-header__overlay-text">{{ headerText }}</span>
      </div>

      <h1 class="p-header__title">
        <span v-if="preTitle" class="pre">{{ preTitle }}</span>
        {{ title }}
        <span v-if="postTitle" class="post">{{ postTitle }}</span>
      </h1>
    </header>
  </template>

  <script setup>
  import { defineProps, ref, onMounted, onUnmounted } from 'vue'
  import { useRoute } from 'vue-router'
  import fitty from 'fitty'

  defineProps({
    headerImg: {
        type: String,
        default: 'https://mm-aws-s3-media.s3.ap-southeast-2.amazonaws.com/large_ROD_5681_9e43064ad9.jpg'
    },
    headerAlt: {
        type: String,
        default: null
    },
    headerText: {
        type: String,
        default: null
    },
    title: {
        type: String,
        default: null
    },
    preTitle: {
        type: String,
        default: null
    },
    postTitle: {
        type: String,
        default: null
    },
    showBackBtn: {
        type: Boolean,
        default: false
    },
    greyScale: {
      type: Boolean,
      default: true
    }
  })

  const route = useRoute()
  const fitText = ref(null)

  const adjustFont = () => {
    fitty(fitText.value, {
      minSize: 20,
      maxSize: 512
    })
  }

  onMounted(() => {
    if (!route.name.includes('stock')) {
      adjustFont()
      setTimeout(() => adjustFont(), 500);
    }
  })

  onUnmounted(() => {
    fitText.value?.unsubscribe()
  })

</script>
