import { chunkArray } from '@/helpers/index'
import { computed, reactive, toRefs, watchEffect } from 'vue'
import { useWindowSize } from '@vueuse/core'

export function handleSlideControls (props) {
    if (!props?.data) return {}
    /* define how many slides to show */
    const { width } = useWindowSize()

    const slideCount = computed(() => {
        if (width.value < 768) return 2
        if (width.value < 1024) return 3
        return 4
    })

    const state = reactive({
        slides: computed(() => chunkArray(props.data, slideCount.value)),
        slideInd: 0,
        slideTransition: null
    })


    const slidesLength = state.slides.length - 1

    // Controllers
    const nextSlides = () => {
        state.slideInd < slidesLength ? state.slideInd++ : ''
        state.slideTransition = 't-slide-right'
    }
    const prevSlides = () => {
        state.slideInd > 0 ? state.slideInd-- : 0
        state.slideTransition = 't-slide-left'
    }

    // Touch events
    const dragStart = () => console.log('drag started')
    const handleDragging = () => console.log('handle dragging')
    const dragEnd = () => console.log('drag ended')

    watchEffect(() => console.log(state.slideInd))

    return {
        ...toRefs(state),
        slideCount,
        nextSlides,
        prevSlides,
        slidesLength,
        dragStart,
        handleDragging,
        dragEnd
    }
}
