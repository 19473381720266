<template>
    <router-link class="card card--product card--slider" :to="{ name: 'boat', params: { id } }">
        <figure class="card__thumb">
            <img v-if="thumbnail" :src="thumbnail" alt="" />
            <!-- TODO: ADD FALLBACK IMAGE -->
            <div class="card__shadow"></div>
        </figure>

        <div class="card__indicator">{{ condition }}</div>

        <section class="card__meta">
            <h3 class="card__title">
                <span class="card__title--pre">{{ year }} {{ make }}</span>
                {{ model }}
            </h3>
        </section>

        <footer class="card__footer">
            <div class="card__brand">
                <component :is="brandLogo" />
            </div>
            <div class="card__price">
                <p class="card__price">{{ price }}</p>
            </div>
        </footer>
    </router-link>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    id: {
        type: [String,Number],
        required: true
    },
    thumbnail: {
        type: String
    },

    title: {
        type: String
    },

    make: {
        type: String,
        default: 'malibu'
    },

    condition: {
        type: String,
        default: 'New',
        validators: (val) => {
            return ['new', 'used', 'damaged'].indexOf(val.toLowerCase()) !== -1
        }
    },

    year: {
        type: String,
        default: ''
    },

    model: {
        type: String,
        required: true
    },

    price: {
        type: [String, Number]
    }
})

const brandLogo = computed(() => `SVGlogo-${props.make.toLowerCase()}`)
</script>

