import { createApp } from 'vue'
import axios from 'axios'
import vueAxios from 'vue-axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import registerComponents from '@/plugins/register-components'
import registerSVGs from '@/plugins/register-svgs'
import browserDetection from '@/plugins/browser-detection'
import { plugin, defaultConfig } from '@formkit/vue'
import { VueQueryPlugin } from "@tanstack/vue-query";

import { API_URL } from '@/helpers/axios'

axios.defaults.baseURL = API_URL

import '@/plugins/browser-detection'

export const app = createApp(App)
    .use(vueAxios, axios)
    .use(store)
    .use(router)
    .use(registerComponents)
    .use(registerSVGs)
    .use(browserDetection)
    .use(plugin, defaultConfig)
    .use(VueQueryPlugin)

app.mount('#app')
