<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74.06 28.57"
    aria-labelledby="mmtitle"
    role="presentation"
  >
    <title id="mmtitle">Malibu Boats</title>
    <g>
      <g>
        <polygon points="50.38 18.84 47.34 18.84 51.1 10.98 54.16 10.98 50.38 18.84"/>
        <path d="M47.22,12.37A2.18,2.18,0,0,0,45.85,12H40.52l-.6,1.24h3.8c.05,0,.38,0,.5.17a.26.26,0,0,1,0,.29l-.47,1,0,.08-.08,0a3.31,3.31,0,0,0-1.3-.28H40.26c-.88,0-1.07.34-1.07.34L37.33,18.6h0a.42.42,0,0,0,0,.31c.13.48,1,.89,1.91.89h5.13l3.07-6.35h0A.8.8,0,0,0,47.22,12.37Zm-5.37,6.22H41s-.32,0-.42-.16a.28.28,0,0,1,0-.26h0l1.08-2.25s.14-.25.67-.25h.94Z" transform="translate(-3.47 -0.97)"/>
        <path d="M66.77,12.38A2.58,2.58,0,0,0,65.39,12h-3l.06-.12,1.1-2.27h-3L55.61,19.8h5.88a5.47,5.47,0,0,0,1.88-.22,1.32,1.32,0,0,0,.95-.72h0L67,13.27h0v0C67.15,13,67.27,12.76,66.77,12.38Zm-2.95,1.27h0l-2.26,4.64h0c-.2.34-.81.3-.87.3H59.21l.06-.13L61.8,13.2l0,0h1.28s.55,0,.7.23A.3.3,0,0,1,63.82,13.65Z" transform="translate(-3.47 -0.97)"/>
        <g>
          <polygon points="45.67 18.84 42.62 18.84 47.54 8.58 50.59 8.58 45.67 18.84"/>
          <path d="M73.78,19.8h-6c-.91,0-1.79-.4-1.91-.88a.42.42,0,0,1,0-.31h0l3.2-6.67h3.05l-3,6.14h0a.3.3,0,0,0,0,.27c.15.22.65.23.71.23H71.3l0-.05L74.48,12h3Z" transform="translate(-3.47 -0.97)"/>
          <path d="M38.9,11.62h0L35,19.81H31l3.79-7.93a.35.35,0,0,0,0-.34c-.19-.29-.83-.3-.91-.3H33l0,.06-4.08,8.51H25l3.79-7.92a.35.35,0,0,0,0-.34c-.19-.29-.83-.3-.91-.3h-1l0,.07L22.7,19.81H18.77L23.63,9.69H30.2s.76,0,1,.38l.07.13.11-.09a3,3,0,0,1,1.87-.43H36.9a2.88,2.88,0,0,1,1.77.56A1,1,0,0,1,38.9,11.62Z" transform="translate(-3.47 -0.97)"/>
          <g>
            <path d="M46.18,22H47a.72.72,0,0,1,.52.17.44.44,0,0,1,.13.33h0a.5.5,0,0,1-.31.47.51.51,0,0,1,.43.48h0c0,.34-.29.55-.73.55h-.9Zm.82.89c.27,0,.46-.12.46-.36h0c0-.2-.15-.33-.44-.33h-.61v.69Zm.08.91c.31,0,.5-.13.5-.35h0c0-.22-.19-.35-.54-.35h-.63v.71Z" transform="translate(-3.47 -0.97)"/>
            <path d="M52.49,23h0a1,1,0,1,1,2,0h0a1,1,0,0,1-1,1A1,1,0,0,1,52.49,23Zm1.81,0h0a.79.79,0,1,0-1.58,0h0a.8.8,0,0,0,.8.83A.78.78,0,0,0,54.3,23Z" transform="translate(-3.47 -0.97)"/>
            <path d="M60.08,22h.22l.92,2H61l-.24-.53H59.64L59.4,24h-.23Zm.57,1.29-.46-1-.46,1Z" transform="translate(-3.47 -0.97)"/>
            <path d="M66.46,22.22h-.67V22h1.58v.21h-.68V24h-.23Z" transform="translate(-3.47 -0.97)"/>
            <path d="M72,23.73l.14-.17a1,1,0,0,0,.69.28c.27,0,.45-.14.45-.34h0c0-.19-.1-.3-.52-.39s-.68-.25-.68-.58h0a.59.59,0,0,1,.66-.55,1.05,1.05,0,0,1,.71.24l-.13.18a.92.92,0,0,0-.58-.22c-.26,0-.43.14-.43.33h0c0,.19.1.3.55.39s.65.26.65.57h0c0,.34-.29.57-.69.57A1.19,1.19,0,0,1,72,23.73Z" transform="translate(-3.47 -0.97)"/>
          </g>
        </g>
        <polygon points="51.58 9.9 52.21 8.59 55.26 8.59 54.63 9.9 51.58 9.9"/>
      </g>
      <path d="M6.85,15.4c0,.41.06.81.1,1.2H18.66l.56-1.2Z" transform="translate(-3.47 -0.97)"/>
      <path d="M7.07,18.05a13.08,13.08,0,0,0,.55,1.7h9.56l.82-1.7Z" transform="translate(-3.47 -0.97)"/>
      <path d="M8.12,21.13a14.76,14.76,0,0,0,13,8.41,14.76,14.76,0,0,0,13-8.41Z" transform="translate(-3.47 -0.97)"/>
      <g>
        <path d="M4.7,9.65a7.6,7.6,0,0,0-.59,1.45c-.06.2-.09.4-.14.6h17.1l.94-2Z" transform="translate(-3.47 -0.97)"/>
        <path d="M3.55,12.83c0,.4-.07.8-.08,1.2H19.9l.53-1.19Z" transform="translate(-3.47 -0.97)"/>
        <path d="M30,8A14.23,14.23,0,0,0,5.42,8Z" transform="translate(-3.47 -0.97)"/>
      </g>
    </g>
  </svg>
</template>
