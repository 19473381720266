<template>
  <component :is="$route.meta.layout" v-if="$route.meta.layout">
    <router-view />
  </component>
</template>

<style lang="scss">
  @import '@/assets/scss/styles.scss';
  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > footer { margin-top: auto; }
  }
</style>
