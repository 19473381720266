<template>
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 273.16 71.87"
    aria-labelledby="logotitle" role="presentation"
  >
  <title id="logotitle">Melbourne Malibu</title>
    <defs>
      <radialGradient id="radial-gradient" cx="164.22" cy="49.13" r="41.48" gradientUnits="userSpaceOnUse">
        <stop offset="0.04" stop-color="#ebe70e"/>
        <stop offset="0.48" stop-color="#f79131"/>
        <stop offset="1" stop-color="#f36c42"/>
      </radialGradient>
      <radialGradient id="radial-gradient-2" cx="164.22" cy="49.13" r="41.48" gradientUnits="userSpaceOnUse">
        <stop offset="0.04" stop-color="#ebe70e"/>
        <stop offset="0.5" stop-color="#f79131"/>
        <stop offset="1" stop-color="#f36c42"/>
      </radialGradient>
      <radialGradient id="radial-gradient-3" cx="155.55" cy="51.81" r="28.1" xlink:href="#radial-gradient-2"/>
      <radialGradient id="radial-gradient-4" cx="150.88" cy="34.95" r="26.7" gradientUnits="userSpaceOnUse">
        <stop offset="0.04" stop-color="#e4e5e6"/>
        <stop offset="1" stop-color="#565458"/>
      </radialGradient>
      <radialGradient id="radial-gradient-6" cx="167.05" cy="25.08" r="0.51" xlink:href="#radial-gradient-4"/>
      <radialGradient id="radial-gradient-7" cx="150.88" cy="34.89" r="26.7" xlink:href="#radial-gradient-4"/>
      <radialGradient id="radial-gradient-8" cx="139.99" cy="25.84" r="3.08" xlink:href="#radial-gradient-4"/>
    </defs>
    <g id="sun">
      <path d="M131.75,46.18c0,.74.11,1.47.19,2.2h21.47l1-2.2Z" transform="translate(-0.44 -0.23)" fill="url(#radial-gradient)"/>
      <path d="M132.16,51a22.08,22.08,0,0,0,1,3.12H150.7L152.2,51Z" transform="translate(-0.44 -0.23)" fill="url(#radial-gradient-2)"/>
      <path d="M134.08,56.68c4.71,9.2,13.6,15.42,23.82,15.42s19.1-6.22,23.81-15.42Z" transform="translate(-0.44 -0.23)" fill="url(#radial-gradient-3)"/>
      <g>
        <path d="M127.81,35.62a15.11,15.11,0,0,0-1.09,2.67c-.1.35-.17.72-.25,1.09h31.36l1.73-3.76Z" transform="translate(-0.44 -0.23)" fill="url(#radial-gradient-4)"/>
        <path d="M125.7,41.45c-.07.73-.12,1.47-.15,2.21H155.7l1-2.19Z" transform="translate(-0.44 -0.23)" fill="url(#radial-gradient-4)"/>
        <g>
          <path d="M167.49,25l-.63-.46-.22.45h.85Z" transform="translate(-0.44 -0.23)" fill="url(#radial-gradient-6)"/>
          <path d="M160.74,31.68c-3,0-6.45-1.32-7.09-3.76a2.81,2.81,0,0,1,.22-2l0-.1,2.72-5.67L155.55,20l-3.9,8.13a5.48,5.48,0,0,1-3.85,3,17.3,17.3,0,0,1-5.11.69H129.63c-.17.27-.34.54-.5.82h45.1c-.19-.33-.39-.65-.59-1Z" transform="translate(-0.44 -0.23)" fill="url(#radial-gradient-7)"/>
          <path d="M139.63,25.15h.23a2.35,2.35,0,0,0,.95-.16l1.8-3.69a25.81,25.81,0,0,0-4.15,2l-.91,1.88h2.08Z" transform="translate(-0.44 -0.23)" fill="url(#radial-gradient-8)"/>
        </g>
      </g>
    </g>
    <g id="text">
      <g id="malibu">
        <polygon points="218.54 53.63 212.97 53.63 219.85 39.29 225.43 39.28 218.54 53.63" fill="#fff"/>
        <path d="M206.86,40.29a3.92,3.92,0,0,0-2.49-.76h-9.73l-1.1,2.26h6.94c.08,0,.7,0,.91.3a.5.5,0,0,1,0,.54l-.85,1.78-.07.15-.14-.07A5.79,5.79,0,0,0,198,44h-3.82c-1.6,0-1.94.61-2,.61l-3.4,7.08h0a.78.78,0,0,0-.06.56c.23.88,1.83,1.62,3.49,1.62h9.36l5.6-11.61h0A1.45,1.45,0,0,0,206.86,40.29Zm-9.79,11.36h-1.56c-.06,0-.58,0-.77-.29a.5.5,0,0,1,0-.49h0l2-4.11c0-.08.26-.47,1.22-.47h1.73Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
        <path d="M242.56,40.31a4.58,4.58,0,0,0-2.51-.78l-5.2,0h-.25l.11-.23,2-4.15h-5.56l-9,18.73h10.74a10.41,10.41,0,0,0,3.43-.4,2.48,2.48,0,0,0,1.75-1.33h0L243,41.94h0l0,0C243.26,41.43,243.49,41,242.56,40.31Zm-5.39,2.32h0l-4.12,8.46h0c-.38.61-1.49.54-1.6.54h-2.68l.11-.23,4.63-9.62,0-.09h2.33c.11,0,1,0,1.29.42A.48.48,0,0,1,237.17,42.63Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
        <polygon points="209.93 53.63 204.37 53.63 213.35 34.9 218.91 34.9 209.93 53.63" fill="#fff"/>
        <path d="M255.36,53.86h-11c-1.66,0-3.26-.74-3.49-1.61a.8.8,0,0,1,.06-.57h0l5.84-12.17h5.56l-5.41,11.21h0a.48.48,0,0,0,0,.49c.26.4,1.17.42,1.28.42h2.61l0-.09,5.77-12h5.56Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
        <path d="M191.67,38.92h0l-7.19,14.94h-7.17l6.91-14.46a.66.66,0,0,0,0-.64c-.34-.52-1.51-.53-1.65-.53h-1.66l-.06.11-7.44,15.52h-7.16l6.91-14.44a.66.66,0,0,0,0-.64c-.34-.52-1.52-.53-1.65-.53h-1.91l-.05.11-7.41,15.5h-7.17l8.86-18.47h12s1.38,0,1.75.71l.12.23.2-.17s.93-.78,3.41-.78H188a5.21,5.21,0,0,1,3.22,1A1.85,1.85,0,0,1,191.67,38.92Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
        <polygon points="220.72 37.32 221.88 34.91 227.44 34.91 226.29 37.32 220.72 37.32" fill="#fff"/>
      </g>
      <g id="melbourne">
        <g>
          <path d="M70.11,22l8.24-.09c2.56,0,3.11-1,3.11-1l4.37-9.2h0a3.76,3.76,0,0,0,.46-1.57c0-1.94-2.92-2.58-5.57-2.58l-17.09.07L54.72,26.15h0a2.32,2.32,0,0,0,.52,3.12c1.37,1.31,4,1.22,4,1.22H77.06l1.75-3.6H65.42c-.14,0-1.12,0-1.46-.49a.82.82,0,0,1,0-.86L65.65,22Zm.75-10.82,4.63-.09c.09,0,.92,0,1.23.45a.79.79,0,0,1,0,.78h0l-2.47,5.24c-.06.12-.41.74-2,.74H67.43Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
          <path d="M257.41,22l8.24-.09c2.55,0,3.1-1,3.1-1l4.38-9.2h0a3.49,3.49,0,0,0,.46-1.57c0-1.94-2.92-2.58-5.57-2.58l-17.09.07L242,26.15h0a2.3,2.3,0,0,0,.52,3.12c1.36,1.31,3.95,1.22,4,1.22h17.85l1.76-3.6H252.72c-.14,0-1.12,0-1.47-.49a.87.87,0,0,1,0-.86L253,22Zm.74-10.82,4.64-.09c.09,0,.92,0,1.23.45a.79.79,0,0,1,0,.78h0l-2.47,5.24c-.06.12-.42.74-2,.74h-4.87Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
          <path d="M127.8,8.48a7.39,7.39,0,0,0-4-1.24l-8.3,0h-.4l.18-.36L118.49.23h-8.87L95.29,30.11h17.13a16.6,16.6,0,0,0,5.47-.64,3.91,3.91,0,0,0,2.79-2.11h0v0l7.8-16.24h0l0-.07C128.91,10.28,129.27,9.6,127.8,8.48Zm-8.6,3.71h0l-6.57,13.5h0c-.6,1-2.38.87-2.55.86l-3.87,0h-.41l.18-.36,7.38-15.35.07-.14h3.72c.17,0,1.63,0,2.05.66A.78.78,0,0,1,119.2,12.19Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
          <path d="M157.32,8.63a7.45,7.45,0,0,0-4-1.25h-.48v0H142.52a16.6,16.6,0,0,0-5.47.64,3.91,3.91,0,0,0-2.79,2.11h0v0l-7.8,16.24h0l0,.07c-.38.72-.74,1.4.73,2.52a7.39,7.39,0,0,0,4,1.24h.48v0h10.32a16.61,16.61,0,0,0,5.47-.65,3.85,3.85,0,0,0,2.79-2.11h0v0L158,11.23h0l0-.07C158.43,10.43,158.79,9.75,157.32,8.63ZM135.76,26.07a.79.79,0,0,1,0-.79h0l6.57-13.5h0c.6-1,2.38-.87,2.55-.86h1.86c.37,0,1.58.08,2,.66a.79.79,0,0,1,0,.78h0l-6.57,13.5h0c-.61,1-2.39.87-2.56.86h-1.86C137.36,26.72,136.14,26.65,135.76,26.07Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
          <polygon points="90 29.88 81.13 29.88 95.45 0 104.33 0 90 29.88" fill="#fff"/>
          <path d="M178.3,30.11H160.74c-2.65,0-5.2-1.18-5.57-2.58a1.28,1.28,0,0,1,.1-.9h0l9.32-19.42h8.87l-8.63,17.9h0a.78.78,0,0,0,0,.78c.42.65,1.88.67,2,.67h4.18l.06-.14,9.21-19.19h8.87Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
          <path d="M59.05,6.27h0L47.57,30.11H36.14L47.16,7a1,1,0,0,0,0-1c-.54-.83-2.42-.86-2.63-.86H41.85l-.09.19L29.88,30.11H18.46l11-23a1,1,0,0,0,0-1c-.53-.83-2.42-.85-2.63-.85h-3l-.09.18L11.87,30.11H.44L14.57.64H33.73s2.2,0,2.79,1.12l.19.38L37,1.86S38.51.62,42.47.62H53.24s3.38-.06,5.14,1.63A3,3,0,0,1,59.05,6.27Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
          <path d="M244.05,11.6h0l-8.91,18.5h-8.87l8.55-17.91a.79.79,0,0,0,0-.79c-.42-.64-1.88-.66-2-.66l-3.17,0h-.15l-.07.15-9.18,19.19h-8.87l11-22.87h17.26s2.62-.05,4,1.25A2.32,2.32,0,0,1,244.05,11.6Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
          <path d="M201.53,10.74h3.72c.17,0,1.63,0,2.05.66a.8.8,0,0,1,0,.79h0l-.17.36h8.75l.71-1.47h0l0-.07c.38-.72.75-1.4-.73-2.52a7.39,7.39,0,0,0-4-1.24l-6.47,0a4.72,4.72,0,0,0-2.67.76l.39-.8h-8.75l-11,22.93h8.8l1.7-3.51.17-.36,7.39-15.35Z" transform="translate(-0.44 -0.23)" fill="#fff"/>
        </g>
      </g>
    </g>
  </svg>
</template>
