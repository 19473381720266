<template>
  <FormKit
    v-if="!successMessage"
    type="form"
    :submit-label="sending ? 'Sending...' : 'Send Enquiry'"
    :submit-attrs="{
      inputClass: `btn btn--primary btn--full ${ sending ? 'btn--loading' : '' }`
    }"
    @submit="handleSubmit"
  >
    <FormKit
      v-model="form.name"
      type="text"
      label="Name"
      validation="required"
    />

    <FormKit
      v-model="form.email"
      type="email"
      label="Email"
      validation="required|email"
    />

      <FormKit
      v-model="form.phone"
      type="tel"
      label="Phone"
    />

    <FormKit
      v-model="form.comments"
      type="textarea"
      label="Comments"
      rows="5"
      validation="required"
      />

      <AppAlert v-if="errorMessage" type="error">
        <strong>{{ errorMessage }}</strong>
      </AppAlert>
  </FormKit>

  <AppAlert v-else type="success" style="grid-column: 1/-1;">
    <strong>{{ successMessage }}</strong>
  </AppAlert>
</template>


<script>
export default {
  inheritAttrs: false
}
</script>

<script setup>
import { ref, reactive } from 'vue'
import { Axios } from '@/helpers/axios'

const form = reactive({
  name: '',
  email: '',
  phone: '',
  comments: ''
})

const successMessage = ref(null)
const errorMessage = ref(null)
const sending = ref(false)

const handleSubmit = () => {
  errorMessage.value = null
  successMessage.value = null
  sending.value = true

  const endpoint = 'https://k1a5qnqzo7.execute-api.ap-southeast-2.amazonaws.com/production/sendEmail'
  const data = {
    ...form,
    subject: `Enquiry from ${form.name} | Melbourne Malibu`,
    message: `Name: ${form.name}\nEmail: ${form.email}\nPhone: ${form.phone}\nComments/Message: \n${form.comments}`
  }

  const resetForm = () => {
    form.name = ''
    form.email = ''
    form.phone = ''
    form.comments = ''
  }

  Axios.post(endpoint, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }})
    .then(({ status, data }) => {
      if (status !== 200 || data.statusCode !== 200) throw new Error('Error sending email')

      resetForm()
      successMessage.value = 'Thank you for your enquiry, we will be in touch shortly.'
    })
    .catch(error => {
      errorMessage.value = error?.message || error || 'Error sending email, please try again later, or email sales@melbournemalibu.com.au'
      console.error(error)
    })
    .finally(() => {
      sending.value = false
    })
}

</script>
