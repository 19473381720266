<template>
    <AppSection class="slider slider--stock">
        <article class="slider__title">
            <h2>{{ title }}</h2>

            <SliderArrows
                @prev-slides="prevSlides"
                @next-slides="nextSlides"
                :slideInd="slideInd"
                :slidesLength="slidesLength"
            />
        </article>

        <transition-group :name="slideTransition">
            <SliderCard
                v-for="(stock, i) in slides[slideInd]"
                v-bind="stock"
                :key="stock.id"
                :style="`--stock-column: ${i + (width >= 768 ? 5 : 1)};`"
            />
        </transition-group>

        <router-link class="slider__more bold" to="/stock">View all stock ></router-link>
    </AppSection>
</template>

<script setup>
import { ref } from 'vue'
import { handleSlideControls } from '@/composables/slideControls'
import { useWindowSize } from '@vueuse/core'

const props = defineProps({
    data: {
        type: [Array,Object],
        required: true
    }
})

const title = ref('Latest Stock')

const {
    slides,
    prevSlides,
    slideInd,
    nextSlides,
    slidesLength,
    slideTransition,
    // slideCount
} = handleSlideControls(props)

const { width } = useWindowSize()


</script>

