<template>
    <AppSection name="socials" class="socials">
        <header class="socials__header">
            <h2 class="socials__title">Follow Us</h2>

            <a href="https://www.facebook.com/melbournemalibu/" target="_blank" class="socials__icon"><SVGfacebook /></a>
            <a href="https://www.instagram.com/melbourne_malibu/?hl=en" target="_blank" class="socials__icon"><SVGinstagram /></a>
        </header>

        <div class="socials__instagram" v-if="data">
            <img
                :key="id"
                :src="media_url"
                alt=""
                v-for="{ id, media_url } in instagramImages"
            />
        </div>

        <AppContent class="invert invert--orange">
            <h2>Stay Tuned</h2>
            <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> -->

            <FormSignup />
        </AppContent>
    </AppSection>
</template>

<script setup>
import { useQuery } from '@/composables/useQuery'
import { computed } from 'vue'

// TODO Make this relative
const { data } = useQuery('instagram', 'https://uat.melbournemalibu.com.au/.netlify/functions/instagramFeed')
const instagramImages = computed(() => {
    if (!data) return []

    return data.value.data
        .filter(({ media_type }) => ['IMAGE', 'CAROUSEL_ALBUM'].includes(media_type))
        .slice(0, 6)
})
</script>

<style lang="scss" scoped>

.socials {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto auto;
    margin-bottom: 0;

    @include breakpoint (md, 'not all') {
        grid-template-columns: repeat(6, 1fr);
    }

    &__header {
        grid-column: 2 / -1;
        grid-row: 1;
        display: flex;
        align-items: baseline;
        margin-bottom: var(--space-md);

        @media only screen and (max-width: 450px) { margin-left: -2em; }

        h2 {
            margin-bottom: 0;
        }
    }

    &__icon {
        fill: var(--color-primary);
        display: inline-block;
        width: 1.1rem;
        margin-left: 0.25rem;

        &:hover { fill: var(--color-secondary); }
        @media only screen and (max-width: 450px) { margin-left: 0.5em; }
    }

    &__instagram {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column: 1 / span 3;
        grid-row: 2 / -1;

        @include breakpoint(md, 'not all') {
            @include breakpoint(sm) { grid-template-columns: repeat(2, 1fr); }
        }

        @include breakpoint(sm, 'not all') {
            grid-column: 1 / -1;
        }

        img {
            aspect-ratio: 1 / 1;
            max-width: 100%;
            @include size(20vw);
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            /* max-width: 100%;
            height: 50%;
            object-fit: cover; */
            @include breakpoint(md, 'not all') {
                @include breakpoint(sm) { &:nth-child(4) ~ img { display: none; } }
            }
        }
    }

    .content {
        display: grid;
        align-items: center;
        align-content: center;
        color: white;
        background: var(--color-primary);
        padding: var(--space-md) var(--space-xl);
        grid-column: 4 / -1;
        grid-row: 2 / -1;

        @include breakpoint(sm, 'not all') {
            grid-column: 1 / -1;
            grid-row: -1;
        }


        // todo ADD TO INVERT CLASS GLOBALLY
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: currentColor;
        }
    }
}
</style>
