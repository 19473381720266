import { createRouter, createWebHistory } from 'vue-router'
import layoutDefault from '@/layouts/LayoutDefault'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: layoutDefault },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: { layout: layoutDefault },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/stock',
    name: 'stock',
    meta: { layout: layoutDefault },
    component: () => import(/* webpackChunkName: "about" */ '../views/PageStock.vue')
  },
  {
      name: 'boat',
      path: '/stock/:id',
      props: true,
      meta: { layout: layoutDefault },
      component: () => import(/* webpackChunkName: "Page Boat" */ '../views/PageBoat.vue')
  },
  {
      name: 'generic',
      path: '/:type/:slug',
      props: true,
      meta: { layout: layoutDefault },
      component: () => import(/* webpackChunkName: "Generic" */ '../views/PageGeneric.vue')
  },
  {
    name: 'contact',
    path: '/contact',
    props: true,
    meta: { layout: layoutDefault },
      component: () => import(/* webpackChunkName: "Contact" */ '../views/PageContact.vue')
  },
  {
    name: 'insurance',
    path: '/insurance',
    props: true,
    meta: { layout: layoutDefault },
      component: () => import(/* webpackChunkName: "Insurance" */ '../views/PageInsurance.vue')
  },
  // newRoute...
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    meta: { layout: layoutDefault },
    component: () => import(/* webpackChunkName: "404" */ '../views/PageNotFound.vue')
  },
]

// router.resolve({
//   name: 'not-found',
//   params: { pathMatch: ['not', 'found'] },
// }).href

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  }
})

export default router
