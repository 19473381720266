<template>
    <section>
      <!-- {{ options }} {{ type }} -->
      <template v-if="type === 'range'">
        <Slider
          v-model="range"
          v-bind="rangeConfig"
          @change="handleSlider"
        />

        <span class="s-filter__range">
          {{ `$${range[0]} - $${range[1]}` }}
        </span>
      </template>

      <template v-else>
        <FormCheckbox
          v-for="(option, index) in filterOptions"
          v-model="filterOptions[index].value"
          :key="option"
          :label="Object.values(option)[0]"
        />
      </template>
    </section>
</template>

<script setup>
  import Slider from '@vueform/slider'
  import FormCheckbox from '@/components/FormCheckbox'
  import { toRaw, ref } from 'vue'

  const props = defineProps({
    options: {
      type: [Object, Array],
      required: true
    },
    type: {
      type: String,
      required: 'checkbox'
    }
  })

  // Slider settings
  const range = ref(toRaw(props.options.value))
  const rangeConfig = {
    min: props.options.min,
    max: props.options.max,
    step: props.options.step,
    tooltips: false,
    lazy: false
  }

  // Checkbox settings
  const _options = toRaw(props.options)
  const filterOptions = ref(_options)

  const handleSlider = (value) => {
    console.log(value)
  }
</script>

<style src="@vueform/slider/themes/default.css"></style>
