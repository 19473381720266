<template>
  <svg class="logo-axis" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.94 27.74" role="presentation" aria-labelledby="axistitle">\
  <title id="axistitle">Axis Boats</title>
    <g>
      <polygon points="28.47 21.68 28.47 21.68 28.47 21.68 28.47 21.68"/>
      <polygon points="36.31 13.75 42.89 6.17 36.13 6.17 33.02 9.7 29.91 6.17 23.16 6.17 29.73 13.75 23.15 21.68 23.16 21.68 30.24 21.67 33.02 18.2 35.8 21.67 42.89 21.68 42.89 21.68 36.31 13.75"/>
      <polygon points="37.58 21.68 37.58 21.68 37.58 21.68 37.58 21.68"/>
    </g>
    <rect x="42.91" y="6.17" width="6.35" height="15.51"/>
    <path d="M75.6,15H69a2.41,2.41,0,0,0-.73-.56,5.51,5.51,0,0,0-1.58-.56l-.11,0h0l-.08,0-.12,0-.14,0-.18,0-.2,0-.22,0-.25,0-.25-.05-.27,0-.27-.05-.28,0L64,13.42l-.27-.05-.27,0-.26,0-.24,0-.23,0-.21,0-.18,0-.3-.05H62l-.09,0a.62.62,0,0,1-.35-.45,1.13,1.13,0,0,1,0-.26,1.17,1.17,0,0,1,.09-.24.77.77,0,0,1,.18-.18.51.51,0,0,1,.17-.07l.09,0H68.9V7.51H61.12l-.51,0a7.7,7.7,0,0,0-3.24.83,3.43,3.43,0,0,0-1.67,1.73,6.6,6.6,0,0,0-.23,4,3,3,0,0,0,.86,1.5,4.21,4.21,0,0,0,1.94.95l.21.05.21,0,.42.07,1.71.29,1.1.18.33.06h.27l.14,0,.11,0,.09,0,0,0a.65.65,0,0,1,.2.17.86.86,0,0,1,.17.64.81.81,0,0,1-.16.43.72.72,0,0,1-.4.26,2,2,0,0,1-.24,0H55.24s0,.81,0,.8c0,1.21,0,2.51,0,2.51v.84h6.88c.54,0,1.09,0,1.64,0a10.53,10.53,0,0,0,3.63-.73,3.27,3.27,0,0,0,2.18-2.71,16.34,16.34,0,0,0,0-3.28,2.15,2.15,0,0,0-.21-.76H75.6Z" transform="translate(-4.65 -1.4)"/>
    <rect x="38.96" y="13.6" width="2.38" height="0.55"/>
    <rect x="23.29" y="13.6" width="4" height="0.55"/>
    <g>
      <path d="M27.8,13.69V7.56l-4.22.85v-7H22.9V8.55L10.72,11v4H4.65v.56h6.07V20L22.9,22.19v7h.67V22.31l4.23.76V16.94l-2.91-.31V14Zm-6.21,2.62-6.83-1s6.83-1.11,6.83-1.1Z" transform="translate(-4.65 -1.4)"/>
      <polygon points="28.46 21.68 28.46 21.68 28.46 21.68 28.46 21.68"/>
    </g>
  </svg>
</template>
