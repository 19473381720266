<template>
    <AppSection name="showroom" class="showroom">
        <figure class="showroom-image showroom-image--main">
            <img :src="service_img_1?.data?.attributes?.url" alt="">
        </figure>
        <figure class="showroom-image showroom-image--secondary">
            <img :src="service_img_2?.data?.attributes?.url" alt="">
        </figure>

        <AppContent>
            <h2>{{ service_title }}</h2>
            <p v-html="service_description" />
            <router-link :to="{ path: service_cta.btn_url }" :class="`btn btn--${service_cta.btn_type}`">
                {{ service_cta.btn_label }}
            </router-link>
        </AppContent>
    </AppSection>
</template>

<script>
export default {
    inheritAttrs: false
}
</script>

<script setup>
defineProps({
    service_title: {
        type: String,
        required: true
    },
    service_description: {
        type: String,
        required: false
    },
    service_cta: {
        type: Object,
        default: () => {}
    },
    service_img_1: {
        type: Object,
        default: () => {}
    },
    service_img_2: {
        type: Object,
        default: () => {}
    }
})
</script>

<style lang="scss" scoped>
    .showroom {
        display: grid;
        grid-template-columns: repeat(9, 1fr) .5fr .5fr;
        grid-template-rows: var(--space-xl) repeat(5, 1fr);

        @include breakpoint(md, 'not all') { grid-template-columns: repeat(7, 1fr) .5fr .5fr; }

        @include breakpoint(sm, 'not all') {
            grid-template-columns: .5fr .5fr repeat(6, 1fr) .5fr .5fr;
            grid-template-rows: repeat(6, minmax(var(--space-xl), auto));
        }

        @include breakpoint(xs, 'not all') {
            grid-template-columns: .5fr .5fr repeat(4, 1fr) .5fr .5fr;
        }

        &-image {
            img { @include imgCover() }

            &--main {
                grid-column: 1 / 5;
                grid-row: 1 / -2;

                @include breakpoint(md, 'not all') { grid-column: 1 / 3; }

                @include breakpoint(sm, 'not all') {
                    grid-column: 1 / 8;
                    grid-row: 1 / 4;
                }

                @include breakpoint(xs, 'not all') { grid-column: 1 / 7; }
            }

            &--secondary {
                grid-column: 3 / 6;
                grid-row: 4 / -1;

                @include breakpoint(md, 'not all') {
                    grid-column: 2 / 4;
                    grid-row: 3 / -1;
                }

                @include breakpoint(sm, 'not all') {
                    grid-column: 4 / -1;
                    grid-row: 3 / 5;
                }
            }
        }

        .content {
            grid-column: 7 / -2;
            grid-row: 2 / -1;

            @include breakpoint(md, 'not all') { grid-column: 5 / -2; }

            @include breakpoint(sm, 'not all') {
                grid-column: 2 / -2;
                grid-row: 6;
            }
        }
    }
</style>
