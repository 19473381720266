<template>
    <div class="slider__arrows">
        <button
            @click="$emit('prev-slides')"
            :disabled="slideInd == 0 && !loop"
        >
            <i class="icon">&#10094;</i>
            <slot name="prevLabel"></slot>
        </button>

        <button
            @click="$emit('next-slides')"
            :disabled="slidesLength <= slideInd && !loop"
        >
            <i class="icon">&#10095;</i>
            <slot name="nextLabel"></slot>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        loop: {
            type: Boolean,
            default: false
        },

        slideInd: {
            type: Number,
            default: 0
        },

        slidesLength: {
            type: Number,
            required: true
        }
    }
}
</script>
