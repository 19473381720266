<template>
    <section :class="['hero hero--full', { 'hero--greyscale' : hero_greyscale }]">
        <figure class="hero__bg">
            <img class="hero__bg-img" :src="heroBg" alt="" />
        </figure>

        <article class="hero__content">
            <h1 class="hero__title" v-html="title" />
            <router-link class="hero__btn btn btn--primary" :to="hero_cta_url">{{ hero_cta_label }} &rarr;</router-link>
        </article>

        <SVGmouse />
    </section>
</template>

<script>
export default {
    inheritAttrs: false
}
</script>

<script setup>
import { computed } from 'vue'
import { getImageUrl } from '@/helpers'

 const props = defineProps({
    hero_title: {
        type: String,
        default: null
    },

    hero_greyscale: {
        type: Boolean,
        default: null
    },

    hero_cta_label: {
        type: String,
        default: null
    },

    hero_cta_url: {
        type: String,
        default: null
    },

    hero_highlight_text: {
        type: String,
        default: null
    },

    hero_background: {
        type: Object,
        default: null
    }
})

const title = computed(() => {
    let heroTitle = props.hero_title

    if (props.hero_highlight_text) {
        const regex = new RegExp(`(${props.hero_highlight_text})`, 'ig')
        const replaceWith = '<span>$1</span>'

        heroTitle = heroTitle
            .trim()
            .split(' ')
            .map(word => word.replace(regex, replaceWith))

        return heroTitle.join(' ')
    }

    return heroTitle
})

const heroBg = computed(() => {
    if (!props.hero_background?.data?.attributes) return null

    return getImageUrl(props.hero_background.data.attributes)
})
</script>

