<template>
    <button :type="type" :class="[`btn btn--${display}`, { 'btn--full' : full }]" @click.prevent.stop="$emit('click', $event)">
        <slot name="icon-left"></slot>
        <slot></slot>
        <slot name="icon-right"></slot>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button'
        },

        display: {
            type: String,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'secondary', 'ghost', 'link'].indexOf(value) !== -1
            }
        },

        full: {
            type: Boolean,
            default: false
        }
    },
}
</script>

