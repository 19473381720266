<template>
    <div
      class="form__group form__group--checkbox"
    >
      <label>
        <input
          v-model="input"
          v-bind="$attrs"
          class="form__input"
          type="checkbox"
        />

        <span class="form__label">
          {{ label }}
        </span>
      </label>
    </div>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>

<script setup>
import { useModelValue } from '@/composables/useModelValue'

const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },

    label: {
      type: String,
      default: ''
    }
})

const emit = defineEmits(['update:modelValue'])

const input = useModelValue(props, emit)
</script>


